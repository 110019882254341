// @import "tailwindcss";
@import url("theme.less");
@import url("./assets/fonts/fonts");
:root {
  --body-background: @body-background;
  --primary-color: @primary-color;
  --success-color: @success-color;
  --green-color: @success-color;
  --danger-color: @danger-color;
  --yellow-color: @yellow-base;
  --modal-header-bg: @modal-header-bg;
}

// GLoBALS STYLE
html,
body {
  font-family: "Poppins", sans-serif;
  //overflow: hidden;
}

.green-color {
  color: var(--green-color);
}

.poppins-font {
  font-family: "Poppins", sans-serif;
}

.roboto-font {
  font-family: "Roboto", sans-serif;
}

.barlow-font {
  font-family: "Barlow", sans-serif;
}

.header-page {
  font-family: "Barlow", sans-serif;
}

.kufam-font {
  font-family: "Kufam", sans-serif;
}

.header-modal {
  font-family: "Kufam", sans-serif;
}

.text-gray-center {
  color: #747683;
  text-align: center;
  font-family: Kufam;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.weni-w-100 {
  width: 100%;
}

.weni-w-75 {
  width: 75%;
}

.weni-w-25 {
  width: 25%;
}

.weni-w-50 {
  width: 50%;
}

.weni-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.weni-h-100 {
  height: 100%;
}

.weni-h-auto {
  height: auto;
}

.object-fit-contain {
  object-fit: contain;
}

.leaflet-transparent-icon {
  background: transparent;
  border: none;
  box-shadow: none;
}

.custom-error-text-color {
  color: #e16363;
}
.weni-jc-between {
  justify-content: space-between;
}

.weni-jc-around {
  justify-content: space-around;
}

.weni-d-flex {
  display: flex;
}

.weni-w-auto {
  width: auto;
}

.weni-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.weni-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.weni-gap-1 {
  gap: 1rem;
}

.weni-gap-0 {
  gap: 0.5rem;
}

.weni-title-form-page {
  color: #464255;
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 1rem;
}

.weni-pane-filter {
  background: rgba(76, 175, 80, 0.05);
  padding: 0.9rem;
  margin: 0 !important;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.weni-bouton {
  width: 101px;
  height: 40px;
  background-color: #079a4b;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: white;
}

.weni-input-search {
  border-radius: 50px !important;
  background: #fff !important;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.09) !important;
  color: #000000 !important;
}

.weni-formGroup {
  position: relative;
  display: block;
}

.weni-detail-separator-item-round {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #d9d9d9;
}

.weni-table-row-item {
  border-radius: 10px;
  background: #eeeeee;
  color: #000000;
  padding: 1rem;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 3px;
}

.weni-table-header {
  border-radius: 10px;
  background: rgba(76, 175, 80, 0.1);
  color: #000000;
  padding: 1rem;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 1rem;
  margin-bottom: 4px;
  width: 100%;
}

.weni-float-left {
  float: left;
}

.weni-float-right {
  float: right;
}

.weni-flex-col {
  display: flex;
  flex-direction: column;
}

.weni-flex-1 {
  flex: 1;
}

.weni-flex-02 {
  flex: 0.2;
}

.weni-flex-row {
  flex-direction: row;
}

.weni-my-1 {
  margin: 1rem 0;
}

.weni-my-2 {
  margin: 2rem 0;
}

.weni-mt-4 {
  margin-top: 4rem;
}

.weni-grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  .grid-2-item {
    background-color: white;
    border-radius: 14px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  }
}

.weni-mt-1 {
  margin-top: 1rem;
}

.weni-mb-1 {
  margin-bottom: 1rem;
}

.weni-mb-0 {
  margin-bottom: 0;
}

.weni-jc-center {
  justify-content: center;
}

.weni-jc-end {
  justify-content: end;
}

.weni-p-0 {
  padding: 0 !important;
}

.weni-p-1 {
  padding: 1rem !important;
}

.weni-m-0 {
  margin: 0 !important;
}

.weni-mt-2 {
  margin-top: 2rem !important;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.weni-items-center {
  align-items: center;
}
.weni-pb-1 {
  padding-bottom: 1rem;
}

.weni-border-t-1 {
  border-top: 1px solid #f2f2f2;
}
.weni-border-b-1 {
  border-bottom: 1px solid #f2f2f2;
}

.primary-icon-color {
  color: var(--primary-color);
}

.color-danger {
  color: var(--danger-color);
}

.weni-cursor-pointer {
  cursor: pointer;
}

.location-color-container {
  width: 15px;
  height: 15px;
  border: 1px solid #f5f5f5;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .location-color {
    width: 10px;
    height: 10px;
    background-color: var(--yellow-color);
    border-radius: 100px;
  }
}

.yellow-icon {
  color: var(--yellow-color);
}
.elipsis-text {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weni-items-end {
  align-items: flex-end;
}

.refresh-tag {
  padding: 6px !important;
  border-radius: 5px !important;
}

.tag-custom {
  border-radius: 10px;
  width: max-content;
  padding: 3px 13px;
  //display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  cursor: pointer;

  span {
    color: #ffffff;

    &:first-child {
      margin-right: 5px;
    }
  }

  .tag-name {
    color: #ffffff;
  }
}

.font-11 {
  font-size: 11px;
}

.tag-custom-circle {
  padding: 10px !important;
  display: flex;
  justify-content: center !important;
  align-items: center;

  span {
    margin: auto !important;
  }
}

.tag-green {
  background-color: var(--green-color);
}

.tag-warning {
  background-color: var(--yellow-color);
}

.tag-danger {
  background-color: var(--danger-color);
}

@media (max-width: 768px) {
  .margin-bottom-sm-6 {
    margin-bottom: 6px;
  }
}

//Customiser les tableaux

.sticky-col {
  border-bottom: 1px solid #e5e7eb;
}

.user__photo {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 100px;
}

.user__info {
  display: flex;
  align-items: center;
  gap: 12px;
}

@container table (min-width: 400px) {
  .sticky-col {
    position: sticky;
    left: 0;
  }

  .sticky-col-right {
    text-align: center;
    span {
      cursor: pointer;
    }
  }
}

@container table (max-width: 400px) {
  td::before {
    content: attr(data-label);
    font-weight: 700;
    color: #374151;
    font-size: 14px;
  }

  label {
    display: none;
  }

  td {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
  }

  td:first-child {
    background: #f9fafb;
  }

  th {
    display: none;
  }
}

#right_panel {
  width: 100%;
  position: relative;
}

#btn_resize {
  cursor: ew-resize;
  width: 14px;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(100% + 3px);
  background: #fff;
  display: flex;
  align-items: start;
  justify-content: center;
  border: none;
  padding: 0;
}

#btn_resize span {
  background: #94a3b8;
  height: 32px;
  width: 4px;
  display: block;
  border-radius: 10px;
}
.large {
  font-size: 80px;
  color: gray;
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn {
  position: relative;
  left: 1px !important;
  top: -1px !important;
}
